.container {
  margin: 0px auto;
  display: block;
  max-width: 1200px;
  width: 90%;
  margin-bottom: 40px;
  text-align: center;
}

.title_container {}

.verbose_builder_subtitle {
  font-size: 13px;
  margin-top: 10px;
  color: gray;
  margin-bottom: 25px;
  text-align: center;
}

.new_btn {
  margin-top: 0px;
  width: 180px;
}

.btn_container {
  margin-bottom: 20px;
}

.card_title {
  font-weight: 500;
  text-align: center;
  font-size: 16px;
  margin-top: 20px;
}

.card_container {
  margin-top: 40px;
}

.card_wrapper {
  /* margin-bottom: 200px; */
  border: "1px solid red"
}

.card {
  width: 18rem;
  margin-right: 1%;
  margin-left: 1%;
  margin-bottom: 20px;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  border: 1px solid rgb(232, 232, 232);
  cursor: pointer;
  transition-duration: 0.6s;
}

.card_description {
  font-size: 13px;
  margin-top: 30px;
  margin-bottom: 30px;
  font-weight: 500;
}

.card:hover {
  box-shadow: rgba(149, 157, 165, 0.8) 0px 8px 24px;
}

.stats_table_header {
  font-size: 13px;
  font-weight: 400;
  text-align: left;
  color: rgb(61, 61, 61) !important;
}

.stats_table_value {
  text-align: right;
  font-size: 13px;
  color: rgb(88, 88, 88) !important;
}
