.label {
  font-weight: bold;
  display: block;
  margin-top: 13px;
  font-size: 14px;
}

textarea,
input {
  font-family: inherit;
}


input,
select,
textarea {
  margin-top: 4px;
  padding: 4px;
  padding-left: 8px;
  padding-right: 8px;

  border: 0.5px solid rgba(63, 63, 63, 0.4);
  border-radius: 5px;
  font-size: 14px;
}

select {
  padding-left: 5px;
}

.backtest_btn {
  margin-top: 20px;
  width: 120px;
  height: 38px;
  background-color: rgb(48, 46, 55);
  border: none;
  border-radius: 5px;
  font-size: 15px;
  transition-duration: 0.3s;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  color: white;
  font-weight: bold;
}

.backtest_btn:hover {
  background-color: rgb(22, 22, 22);
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  transform: translate(0px, -0.5px);
  cursor: pointer;
}

.error_wrapper {
  background-color: #ec6363;
  display: block;
  border-radius: 7px;
  padding-left: 15px;
  padding-right: 15px;
  padding-top: 15px;
  padding-bottom: 5px;
}

.error_container {
  margin-top: 20px;
  width: 80%;
}

.label_subtext {
  margin: 0px;
  font-size: 11px;
  color: rgb(100, 100, 100);
  margin-top: 1px;
  margin-bottom: 2px;
  width: fit-content;
  max-width: 380px;
}

@media only screen and (max-width: 600px) {
  .backtest_btn {
    width: 140px;
  }
}

.gpt_submit {
  width: 25px;
  height: 25px;
  background-color: black;
  color: white;
  font-size: 12px;
  border: none;
  font-weight: lighter;
  cursor: pointer;
  border-radius: 6px;
  margin-left: 7px;
  margin-top: 2px;
}

input:placeholder-shown {
  font-style: italic;
}

.gpt_input_wrapper {
  display: flex;
  align-items: center;
}

.bookmark_container {
  width: fit-content;
  position: absolute;
  right: 23px;
  top: 23px;
}

.verbose_builder {
  margin: 0px auto;
}

.verbose_builder_subtitle {
  font-size: 13px;
  margin-top: 10px;
  color: gray;
  margin-bottom: 25px;
}

.verbose_heading_container {
text-align: center;
}

@media only screen and (max-width: 600px) {
  .verbose_heading_container {
    display: none;
  }
  .verbose_tile {
    width: 95%;
    padding-left: 0px;
  }
}

.verbose_tile {
  margin: 0px auto;
}


.verbose_inner_column_wrapper {
  /* margin: 0px auto; */
  /* width: 400px; */
  /* width: fit-content; */
  /* border: 1px solid black */
}

.verbose_button_container {
  display: block;
  margin: 0px auto;
  margin-top: 50px;
  min-height: 40px;
}

.verbose_backtest_btn {
  margin: 0px auto;
  display: block;
}

.verbose_error_container {
  margin: 0px auto;
  display: block;
  width: 200px;
  margin-top: 10px;
  text-align: center;
}