.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.3);
  overflow: scroll;
  z-index: 99;
}

.modal_content {
  position: relative;
  display: block;
  margin: 0px auto;
  margin-top: 10%;
  /* transform: translate(-50%, -50%); */
  background-color: #fff;
  padding: 20px;
  border-radius: 2px;
  text-align: center;
  width: 90%;
  max-width: 500px;
  min-height: 350px;
  max-height: calc(100vh - 20%);
  overflow: scroll;
}

.help_modal {
  margin-top: 2%;
  max-width: 700px;
  width: 70%;
  max-height: 90%;
  margin-bottom: 50px;
  padding: 50px;
  padding-top: 0px;
}

.close {
  position: absolute;
  top: 10px;
  right: 20px;
  font-size: 20px;
  cursor: pointer;
}

.help_gif {
  margin-top: 20px;
  margin-bottom: 20px;
  width: 100%;
  max-width: 600px;
}

.contact_btn {
  margin-top: 20px;
  width: 100px;
  height: 28px;
  background-color: rgb(48, 46, 55);
  border: none;
  border-radius: 5px;
  font-size: 13px;
  transition-duration: 0.3s;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  color: white;
  font-weight: bold;
}

.contact_btn:hover {
  background-color: rgb(22, 22, 22);
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  transform: translate(0px, -0.5px);
  cursor: pointer;
}

.help_text_container {
  width: 100%;
  display: block;
  margin: 0px auto;
}

.help_text {
  text-align: left;
  color: rgb(72, 72, 72);
  font-size: 15px;
}

.demo_backtest_btn {
  width: 090px;
  height: 25px;
  background-color: rgb(48, 46, 55);
  border: none;
  border-radius: 5px;
  font-size: 11px;
  transition-duration: 0.3s;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  color: white;
  font-weight: bold;
  margin-top: 10px;
}

.mobile_close_btn {
  display: none;
  margin: 0px auto;
  text-align: center;
  width: 120px;
  height: 35px;
  background-color: rgb(48, 46, 55);
  border: none;
  border-radius: 5px;
  font-size: 14px;
  transition-duration: 0.3s;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  color: white;
  font-weight: bold;
  margin-top: 30px;
  margin-bottom: 20px;
}

.contact_form_email_input {
  width: 90%;
  max-width: 300px;
}

.contact_form_message_input {
  width: 90%;
  max-width: 400px;
  height: 100px;
}

.modal_title {
  margin-top: 40px;
}

@media only screen and (max-width: 600px) {
  .demo_backtest_btn {
    margin-top: 3px;
    font-size: 10px;
  }

  .modal_content {
    width: 80%;
    margin-top: 20%;
    margin-bottom: 20px;
  }

  .help_modal {
    margin-top: 10%;
  }

  .mobile_close_btn {
    display: block;
  }
}