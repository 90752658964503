.nav {
  background-color: #1d1c21;
  margin-bottom: 30px;
  position: relative;
  width: 100%;
  font-size: 14px;
}

.nav_link {
  font-size: 14px;
}