.table {
  width: 100%;
  border-collapse: collapse;
  border-spacing: 0;
  font-family: Arial, sans-serif;
  font-size: 14px;
  border: 1px solid rgb(192, 192, 192);
  max-width: 600px;
  text-align: left;
}

.fs_container {
  margin: 0px auto;
  width: 90%;
  margin-top: 10px;
  max-width: 1000px;
  overflow-x: hidden;
  overflow-y: visible;
  left: 0;
  right: 0;
}

.chart_container {
  width: 90%;
  max-width: 200px;
  display: block;
  margin: 0px auto;

}

@media only screen and (max-width: 600px) {
  .chart_container {
    margin-top: 30px;
  }
}

.table th,
.table td {
  border: 1px solid #e0e0e0;
  padding: 8px;
  text-align: center;
}

.table th {
  background-color: #f5f5f5;
  font-weight: bold;
}

.table tr.buy {
  background-color: #d9f9e7;
}

.table tr.sell {
  background-color: #f9dada;
}

.tab_title {
  font-weight: 500;
  color: rgb(100, 100, 100);
}

.tab_title_active {
  font-weight: 600;
  color: rgb(22, 22, 22);
}

.tab_title:hover {
  color: rgb(37, 37, 37);
}

.invest_title {
  margin-top: 0px;
  font-weight: 500;
  text-align: center;
  margin-bottom: 0px;
}

.subtext {
  font-size: 12px;
  text-align: center;
  margin-top: 5px;
}

.deposit_btn {
  font-size: 13px;
  display: block;
  width: 100px;
  height: 30px;
  text-align: center;
  margin: 0px auto;
}

.deposit_input {
  margin: 0px auto;
  display: block;
  text-align: center;
  margin-top: 10px;
  margin-bottom: 10px;
}

.latest_holdings_container {
  border-right: 0.5px solid rgb(174, 174, 174);
  padding-top: 10px;
  padding-bottom: 20px;
}

@media only screen and (max-width: 767px) {
  .latest_holdings_container {
    border-right: none;
  }
}

.invest_modal_pagination_container {
  display: block;
  margin: 0px auto;
  margin-top: 30px;
  /* position: absolute; */
  width: fit-content;
}