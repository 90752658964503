.backtest_chart_wrapper {
  height: 50vh;
  max-width: 100%;
  overflow: hidden;
  left: 0;
  right: 0;
}

@media only screen and (max-width: 600px) {
  .backtest_chart_wrapper {
    height: 40vh;
  }
}