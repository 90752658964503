.my_container {
  display: flex;
  justify-content: space-between;
  width: 95%;
  display: 0px auto;
  align-items: flex-start;
  /* min-height: 600px; */
  max-width: 2000px;
  margin: 0px;
  /* border: 1px solid red; */
}

.centered_container {
  display: flex;
  justify-content: center;
  align-items: flex-start;
}

.column {
  flex: 1;
  display: flex;
  flex-direction: column;
  /* align-items: center; */
  justify-content: center;
  height: 100%;
  width: 100%;
}

.form_wrapper {
  flex-grow: 2;
  margin-right: 20px;
}

.backtest_chart_container {
  flex-grow: 4;
  overflow-x: hidden;
  overflow-y: visible;
  padding-bottom: 30px;
}


.tile {
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  width: fit-content;
  padding: 20px;
  padding-left: 30px;
  min-width: 40px;
  padding-bottom: 30px;
  min-width: 300px;
  border-radius: 10px;
}

@media only screen and (max-width: 600px) {
  .my_container {
    display: block;
  }

  .column {
    display: block;
    width: 95%;
    margin: 0px auto;
  }

  .tile {
    margin: 0px auto;
    width: 100%;
    padding-left: 20px;
  }

  .backtest_chart_wrapper {
    margin-top: 30px;
    width: 100%;
    overflow-x: visible;
    overflow-y: visible;
  }
}



.subtext {
  font-size: 13px;
  color: rgb(100, 100, 100);
}

.nav {
  width: 100%;
  height: 50px;
  background-color: rgb(29, 28, 33);
  margin-bottom: 30px;
  position: relative;
}

.nav_title {
  color: rgb(235, 235, 235);
  margin: 0px;
  font-weight: 600;
  margin-left: calc(2.5% + 30px);
  position: absolute;
  top: 15px;
  cursor: pointer;
  transition-duration: 0.2s;
  font-size: 16px;
}

.nav_title:hover {
  font-size: 16.5px;
  transform: translate(-1px, -0.2px);
  color: white;
}

.nav_element_text {
  color: rgb(202, 202, 202);
  margin: 0px;
  margin-top: 18px;
  font-size: 13px;
  text-align: right;
  cursor: pointer;
  transition-duration: 0.2s;
}

.nav_element_wrapper {
  width: 100px;
  margin-left: 0px;
  height: 100%;
}

.nav_element_text:hover {
  font-size: 13.5px;
  transform: translate(1.4px, -0.5px);
  color: white;
}

.nav_element_container {
  display: flex;
  position: absolute;
  right: calc(2.5% + 16px);
  height: 100%;
}

.bond_ad {
  width: 100%;
  height: 30px;
  background-color: #206D69;
  cursor: pointer;
}


.bond_ad_text {
  text-align: center;
  color: white;
  padding-top: 7px;
  margin-top: 0px;
  font-size: 13px;
}
